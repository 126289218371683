import { StyledAvatar as Avatar, StyledMoon as Moon, theme as Theme, StyledImage as Image, StyledSlideLayout as Layout } from 'src/components';
import React from "src/images/react.png";
import GenerativeReact from "src/images/generative-react.png";
import StaticGen from "src/images/static-gen.png";
import GraphQLSample from "src/images/graphql-sample.png";
export default {
  Avatar,
  Moon,
  Theme,
  Image,
  Layout,
  React,
  GenerativeReact,
  StaticGen,
  GraphQLSample
};